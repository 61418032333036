// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-contact">
                <p>El.Masla, El-Wafaa Building, Fayoum, 63511, Egypt</p>
                <p>Email: <a href="mailto:info@qytra.com">info@qytra.com</a></p>
                <p>Phone: +201023507528</p>
            </div>
            <div className="footer-social">
                <a href="https://www.facebook.com/qytraa" target="_blank" rel="noopener noreferrer">
                    <img src="/facebook.png" alt="Facebook" />
                </a>
                <a href="https://www.linkedin.com/company/qytra/" target="_blank" rel="noopener noreferrer">
                    <img src="/linkedin.png" alt="LinkedIn" />
                </a>
                <a href="https://www.instagram.com/qyrtaherbs/" target="_blank" rel="noopener noreferrer">
                    <img src="/instgram.png" alt="Instagram" />
                </a>
                <a href="mailto:info@qytra.com" target="_blank" rel="noopener noreferrer">
                    <img src="/email.png" alt="Email" />
                </a>
            </div>
            <div className="footer-copyright">
                <p>&copy; 2024 Qytra. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
