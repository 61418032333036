// src/components/AboutUs.js
import React from 'react';
import './AboutUs.css';
const AboutUs = () => {
    return (
        <section id="about-us" className="about-us">
            <h2>About Us</h2>
            <img src="/about-us.jpg" alt="About Us" className="about-us-image" />
            <p>Welcome to Qytra, where ancient traditions meet modern excellence in the world of herbs. Founded in 2021, Qytra has quickly established itself as a respected name in the local herb market.</p>
            <p>The name "Qytra" is derived from an ancient Egyptian word meaning "quality." We deliberately replaced the traditional 'K' with 'Q' to symbolize our steadfast commitment to quality and excellence in every aspect of our operations.</p>
        </section>
    );
};

export default AboutUs;
