// src/components/QualityProduction.js
import React from 'react';
import './QualityProduction.css';

const QualityProduction = () => {
  return (
    <section id="quality-production" className="quality-production">
      <h2> Production</h2>
      <p>At Qytra, quality is our priority. Our production halls utilize advanced sifting equipment to meticulously remove foreign particles and sieve out unwanted materials, ensuring purity.</p>
      <p>We offer products in various grades—crushed, coarse, and fine—tailored to customer needs. Our production process includes crushing, garbling, and color sorting, all integrated with metal detectors to ensure contaminant-free products.</p>

      <div className="quality-images">
        <img src="/quality3.jpg" alt="Quality Control" />
        <img src="/quality2.jpg" alt="Production Line" />

      </div>
      <div id="certificate" className="certificate">
        <h2>Quality</h2>
        <p>
          Qytra proudly holds the ISO 9001 certification, which attests to our robust quality management system. This certification ensures that our processes consistently meet customer and regulatory requirements. It demonstrates our commitment to continuous improvement and excellence in every aspect of our operations.</p>
        <p>
          Our ISO 22000 certification highlights our comprehensive food safety management system, integrating hazard analysis and critical control points (HACCP) principles. This certification ensures that our products are safe for consumption, meeting stringent international food safety standards.</p>
        <p>
          Adhering to Good Manufacturing Practices (GMP), Qytra guarantees that our products are consistently produced and controlled according to quality standards. Our GMP plan covers all aspects of production, from raw material sourcing to final product delivery, ensuring that our products are safe, pure, and of the highest quality.
        </p>
        <div className="certificate-images">
          <img src={`${process.env.PUBLIC_URL}/iso22000.png`} alt="ISO 22000 Certificate" />
          <img src={`${process.env.PUBLIC_URL}/iso9001.png`} alt="Another Certificate" />
          <img src={`${process.env.PUBLIC_URL}/gmp.png`} alt="Yet Another Certificate" />
        </div>
      </div>
    </section>
  );
};

export default QualityProduction;
