// src/components/Home.js
import React, { useState, useEffect } from 'react';
import './Home.css';

const images = [
    `${process.env.PUBLIC_URL}/Homepage-Image.jpg`,
    `${process.env.PUBLIC_URL}/iso22000.png`,
    `${process.env.PUBLIC_URL}/iso9001.png`,
    `${process.env.PUBLIC_URL}/gmp.png`,
    `${process.env.PUBLIC_URL}/contact-us.jpg`,
    `${process.env.PUBLIC_URL}/about-us.jpg`,
    `${process.env.PUBLIC_URL}/quality1.jpg`,
    `${process.env.PUBLIC_URL}/quality2.jpg`,
    `${process.env.PUBLIC_URL}/quality3.jpg`,
    `${process.env.PUBLIC_URL}/products/dill.jpg`,
    `${process.env.PUBLIC_URL}/products/fennel.jpg`,
    `${process.env.PUBLIC_URL}/products/lemongrass.jpg`,
    `${process.env.PUBLIC_URL}/products/spearmint.jpg`,
    `${process.env.PUBLIC_URL}/products/basil.jpg`,
    `${process.env.PUBLIC_URL}/products/fenugreek.jpg`
];

const Home = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        // Preload images
        images.forEach((src) => {
            const img = new Image();
            img.src = src;
        });

        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 3) % images.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const getVisibleImages = () => {
        if (index + 3 > images.length) {
            return [...images.slice(index), ...images.slice(0, (index + 3) % images.length)];
        }
        return images.slice(index, index + 3);
    };

    return (
        <section id="home" className="home">
            <h1>Fresh from Nature, Perfectly Preserved</h1>
            <p className="subtitle">Your Source for Premium Dried Herbs</p>
            <p className="description">
                Welcome to Qytra, your trusted partner in sourcing the finest dried herbs. Our products include basil, dill weed, lemongrass leaves, fennel seeds, and fenugreek seeds. With a commitment to quality and sustainability, we bring the best of Egyptian agriculture to the global market.
            </p>
            <div className="gallery">
                {getVisibleImages().map((image, i) => (
                    <img key={i} src={image} alt={`Gallery ${i}`} />
                ))}
            </div>
        </section>
    );
};

export default Home;