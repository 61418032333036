import React, { useState } from 'react';
import './Navbar.css';

const Navbar = () => {
    const [menuActive, setMenuActive] = useState(false);

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    return (
        <nav className="navbar">
            <div className="logo">
                <img src="/Qytra-logo.png" alt="Logo" />
            </div>
            <div className="navbar-menu-icon" onClick={toggleMenu}>
                <i className={menuActive ? 'fas fa-bars' : 'fas fa-bars'}></i>
            </div>
            <ul className={`nav-links ${menuActive ? 'active' : ''}`}>
                <div className="close-icon" onClick={toggleMenu}>
                    <i className="fas fa-times"></i>
                </div>
                <li><a href="#home" onClick={toggleMenu}>Home</a></li>
                <li><a href="#about-us" onClick={toggleMenu}>About Us</a></li>
                <li><a href="#products" onClick={toggleMenu}>Products</a></li>
                <li><a href="#quality-production" onClick={toggleMenu}>Production & Quality</a></li>
                <li><a href="#contact-us" onClick={toggleMenu}>Contact Us</a></li>
            </ul>
        </nav>
    );
};

export default Navbar;
