import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './SendInquiry.css';

const SendInquiry = () => {
    const form = useRef();
    const [successMessage, setSuccessMessage] = useState('');

    const sendInquiry = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_t5kt64l', 'template_blejnrq', form.current, '41Z_5_V58AY8ZgXt2')
            .then(
                () => {
                    setSuccessMessage('Your inquiry has been sent successfully!');
                    form.current.reset();
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 3000); // 3 seconds
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
    };

    return (
        <section id="send-inquiry" className="send-inquiry">
            <h2>Send Inquiry</h2>
            <form ref={form} onSubmit={sendInquiry}>
                <input type="text" name="businessName" placeholder="Business Name" />
                <input type="text" name="contactPerson" placeholder="Contact Person" />
                <input type="email" name="email" placeholder="Your Email" />
                <input type="text" name="product" placeholder="Product" />
                <input type="text" name="quantity" placeholder="Quantity" />
                <textarea name="message" placeholder="message"></textarea>
                <button type="submit">Send Inquiry</button>
            </form>
            {successMessage && <div className="success-notification">{successMessage}</div>}
        </section>
    );
};

export default SendInquiry;
