import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactUs.css';

const ContactUs = () => {
    const form = useRef();
    const [successMessage, setSuccessMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_t5kt64l', 'template_dmi4q9a', form.current, '41Z_5_V58AY8ZgXt2')
            .then(
                () => {
                    setSuccessMessage('Your message has been sent successfully!');
                    form.current.reset();
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 3000); // 3 seconds
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
    };

    return (
        <section id="contact-us" className="contact-us">
            <h2>Contact Us</h2>
            <img src="/contact-us.jpg" alt="Contact Us" className="contact-us-photo" />
            <div className="contact-content">
                <div className="contact-info">
                    <div className="info-box">
                        <h3>Our Address</h3>
                        <p>El.Masla, El-Wafaa Building, Fayoum, 63511, Egypt</p>
                    </div>
                    <div className="info-box">
                        <h3>Email Us</h3>
                        <p>Email: <a href="mailto:info@qytra.com">info@qytra.com</a></p>
                    </div>
                    <div className="info-box">
                        <h3>Call Us</h3>
                        <p>Phone: +201023507528</p>
                    </div>
                </div>
                <form ref={form} onSubmit={sendEmail} className="contact-form">
                    <input type="text" name="user_name" placeholder="Your Name" />
                    <input type="email" name="user_email" placeholder="Your Email" />
                    <input type="text" name="subject" placeholder="Subject" />
                    <textarea name="message" placeholder="message"></textarea>
                    <button type="submit">Send Message</button>
                </form>
                {successMessage && <div className="success-notification">{successMessage}</div>}
            </div>
        </section>
    );
};

export default ContactUs;
