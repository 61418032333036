// src/App.js
import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Products from './components/Products';
import QualityProduction from './components/QualityProduction';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import SendInquiry from './components/SendInquiry';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  return (
    <div>
      <Navbar />
      <Home />
      <Products />
      <QualityProduction />
      <AboutUs />
      <ContactUs />
      <SendInquiry />
      <Footer />
    </div>
  );
};

export default App;
