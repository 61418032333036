// src/components/Products.js
import React from 'react';
import './Products.css';

const products = [
    { name: 'Dill Weed Leaves', description: 'Aromatic and flavorful, our dried dill weed is a versatile herb that adds a burst of flavor to any dish. Perfect for pickling, salads, and fish dishes.', image: '/products/dill.jpg' },
    { name: 'Fennel Seeds', description: 'Our fennel seeds are perfect for culinary and medicinal uses, offering a sweet and aromatic flavour profile.', image: '/products/fennel.jpg' },
    { name: 'Lemongrass Leaves', description: 'Our dried lemongrass imparts a fresh, lemony flavor with a hint of ginger, ideal for various cuisines. Great for teas, soups, and curries.', image: '/products/lemongrass.jpg' },
    { name: 'Spearmint Leaves', description: 'Milder than peppermint, our dried spearmint leaves are perfect for culinary and medicinal uses. Great for teas, salads, and sauces.', image: '/products/spearmint.jpg' },
    { name: 'Basil Leaves', description: 'Our dried basil leaves retain the herbs sweet, earthy flavor, perfect for enhancing your culinary creations. Ideal for sauces, soups, and seasoning blends.', image: '/products/basil.jpg' },
    { name: 'Fenugreek Seeds', description: 'Known for its health benefits and unique flavour, our fenugreek seeds are a staple in many cuisines.', image: '/products/fenugreek.jpg' },
];

const Products = () => {
    return (
        <section id="products" className="products">
            <h2>Our Signature Products</h2>
            <div className="product-list">
                {products.map((product, index) => (
                    <div key={index} className="product-item">
                        <img src={product.image} alt={product.name} />
                        <h3>{product.name}</h3>
                        <p>{product.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Products;
